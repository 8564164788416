import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport
} from "@mui/x-data-grid";

import moment from "moment";

export function TableToolbar({ csvFileName = "cc-export" }) {
  const date = moment().format("YYYY-MM-DD");

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: `${csvFileName}-${date}`
        }}
      />
    </GridToolbarContainer>
  );
}
